import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { platform, IOS } from "@vkontakte/vkui";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import {
  Icon16Add,
  Icon16Cancel,
  Icon28FavoriteOutline,
} from "@vkontakte/icons";
import {
  Header,
  SimpleCell,
  Button,
  PanelHeaderButton,
  PanelHeaderBack,
  PanelHeaderContent,
  ContentCard,
  MiniInfoCell,
  Div,
  Text,
  Card,
  CardGrid,
  Counter,
} from "@vkontakte/vkui";
import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";
import { appUrl, shopId } from "./../config";
import MyTabBar from "./MyTabBar";


function Position({ id, params, go, dispatchStore, store }) {
  const [positions, setPositions] = useState([]);
  const categoryId = params.categoryId;
  const osName = platform();
  useEffect(() => {
    fetch(`${appUrl}/api/v1.0/Shop/${shopId}/Category/${categoryId}/Position`)
      .then((request) => request.json())
      .then((data) => setPositions(data));
  }, [categoryId]);
  const largeImageStyles = {
    width: "100%",
    maxHeight: 124,
    borderRadius: 8,
    //border: "1px solid var(--placeholder_icon_background)",
    objectFit: "cover",
  };
  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack label="Назад" onClick={go} data-to="category" />}
      >
        Позиции для категории
      </PanelHeader>

      <Group title="Navigation Example">
        <Group description="Аватарки для приложений. Радиус скургления зависит от значения свойства size.">
          <Header mode="secondary">Приложения</Header>

          <CardGrid size="m">
            {positions.map((cat) => {
              let orderPos = store.orderCkeckout.find(
                (x) => x.itemId == cat.id
              ) || { count: 0 };
              return (
                <Card
                  style={{ cursor: "pointer" }}
                  mode="plain"
                  key={cat.id}
                  data-category-id={cat.id}
                  data-to="position"
                >
                  <img style={largeImageStyles} src={cat.imageUrl} />
                  <Header
                    mode="primary"
                    /*indicator="12" */ 
                  >
                    {cat.name}
                  </Header>
                  <Div>
                    <Text  >
                      {cat.description}
                    </Text>
                  </Div>
                  <SimpleCell
                    disabled
                    indicator={
                      <Counter mode="primary">{orderPos.count}</Counter>
                    }
                  >
                    <Button
                      size="s"
                      before={<Icon16Add />}
                      onClick={() => {
                        dispatchStore({
                          type: "addOrderPoition",
                          data: cat,
                        });
                      }}
                    >
                      {cat.price} р.
                    </Button>{" "}
                  </SimpleCell>
                </Card>
              );

              return (
                <ContentCard
                  mode="plain"
                  key={cat.id}
                  image={cat.imageUrl}
                  header={cat.name}
                  caption={cat.description}
                  maxHeight={150}
                  text={
                    <>
                      {/* <Button
                        onClick={() => {
                          debugger;
                          dispatchStore({ type: "addOrderPoition", data: cat });
                        }}
                        before={<Icon16Add />}
                      >
                        {cat.price} р.
                      </Button>
                      {orderPos.count && (
                        <Button
                          mode="tertiary"
                          after={<Counter size="s">{orderPos.count}</Counter>}
                        >
                          Выбрано
                        </Button>
                      )}*/}
                      <SimpleCell
                        disabled
                        indicator={
                          <Counter mode="primary">{orderPos.count}</Counter>
                        }
                      >
                        <Button
                          size="s"
                          before={<Icon16Add />}
                          onClick={() => {
                            dispatchStore({
                              type: "addOrderPoition",
                              data: cat,
                            });
                          }}
                        >
                          {cat.price} р.
                        </Button>{" "}
                      </SimpleCell>
                    </>
                  }
                  data-category-id={cat.id}
                />
              );
            })}
          </CardGrid>
          
        </Group>
      </Group>
      < MyTabBar go={go} store={store}/>
    </Panel>
  );
}

Position.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default Position;
