import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import {Icon28ClipOutline,Icon28NewsfeedOutline,Icon28MessageOutline} from "@vkontakte/icons"
import {
  Header,
  SimpleCell,
  CardGrid,
  Card,
  ContentCard,
  PanelHeaderContent,
  PanelHeaderBack,
  Tabbar,

  TabbarItem,
  Counter
} from "@vkontakte/vkui";
import { appUrl, shopId } from "./../config";

import MyTabBar from "./MyTabBar";

function Category({ id, go,store }) {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    fetch(`${appUrl}/api/v1.0/Shop/${shopId}/Category`)
      .then((request) => request.json())
      .then((data) => setCategory(data));
  }, []);

  const largeImageStyles = {
    width: "100%",
    maxHeight: 124,
    borderRadius: 8,
    //border: "1px solid var(--placeholder_icon_background)",
    objectFit: "cover",
  };

  return (
    <Panel id={id}>
      <PanelHeader>Категории</PanelHeader>

      <Group title="Navigation Example">
        <CardGrid size="m">
          {category.map((cat) => {
            /*<ContentCard
            mode="plain"
              key ={cat.id}
              image={cat.imageUrl}
              header={cat.name}
              caption={cat.name}
              maxHeight={150}
              onClick={go}
              
              data-category-id={cat.id}
              data-to="position"
            />
            
          */
            return (
              <Card
                style={{ cursor: "pointer" }}
                mode="plain"
                key={cat.id}
                onClick={go}
                data-category-id={cat.id}
                data-to="position"
              >
                <img style={largeImageStyles} src={cat.imageUrl} />
                <Header mode="primary"  /*indicator="12" */>{cat.name}</Header>
              </Card>
            );
          })}
        </CardGrid>
      </Group>
      < MyTabBar go={go} store={store}  />
    </Panel>
  );
}

Category.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default Category;
