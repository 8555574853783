import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import { Icon16Add } from "@vkontakte/icons";
import { Header, SimpleCell, Button, Separator } from "@vkontakte/vkui";
import { appUrl, shopId } from "./../config";
import { Icon16MinusCircleOutline } from "@vkontakte/icons";

import MyTabBar from "./MyTabBar";
function Checkout({ id, go, store, dispatchStore }) {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    fetch(`${appUrl}/api/v1.0/Shop/${shopId}/Category`)
      .then((request) => request.json())
      .then((data) => setCategory(data));
  }, []);

  return (
    <Panel id={id}>
      <PanelHeader>Корзина</PanelHeader>
      <Group title="Navigation Example">
        <Group description="Аватарки для приложений. Радиус скургления зависит от значения свойства size.">
          <Header mode="secondary">Приложения</Header>

          {store.orderCkeckout.map((cat) => (
            <SimpleCell
              key={cat.id}
              before={<Avatar mode="app" src={cat.imageUrl} />}
              description={cat.description}
              after={
                <>
                  <Button
                    size="s"
                    before={<Icon16Add />}
                    onClick={() => {
                      dispatchStore({
                        type: "addOrderPoition",
                        data: cat,
                      });
                    }}
                  />
                  <span style={{ padding: "5px" }}>{cat.count}</span>
                  <Button
                    size="s"
                    before={<Icon16MinusCircleOutline />}
                    onClick={() => {
                      dispatchStore({
                        type: "removeOrderPoition",
                        data: cat,
                      });
                    }}
                  />
                  <span style={{ padding: "5px" }}>{` ${
                    cat.price * cat.count
                  }р.`}</span>
                </>
              }
            >
              {cat.name}
            </SimpleCell>
          ))}
        </Group>
        <Separator style={{ margin: "12px 0" }} />
        <SimpleCell
        after={<>{store.orderCkeckout.reduce((b,a) => a.count * a.price + b, 0)} p.  </>}
        >
          К оплате
        </SimpleCell>
      </Group>
      <Button
                    size="l"
                    before={<Icon16MinusCircleOutline />}
                    data-to={"checkout-form"}
                    onClick={go} >Оформить</Button>
      <MyTabBar go={go} store={store} />
    </Panel>
  );
}

Checkout.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default Checkout;
