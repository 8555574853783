import React from "react";
import {
  Icon28ClipOutline,
  Icon28NewsfeedOutline,
  Icon28MessageOutline,
} from "@vkontakte/icons";
import { Icon28ShoppingCartOutline } from "@vkontakte/icons";
import { Icon28ListOutline } from "@vkontakte/icons";
import { Tabbar, TabbarItem, Counter } from "@vkontakte/vkui";

function MyTabBar({ go, store }) {
  return (
    <Tabbar>
      <TabbarItem text="Каталог" onClick={go} data-to="category">
        <Icon28ListOutline />
      </TabbarItem>
      <TabbarItem
        indicator={
          <Counter size="s" mode="prominent">
            {store.orderCkeckout.length}
          </Counter>
        }
        text="Корзина"
        onClick={go}
        data-to={"checkout"}
      >
        <Icon28ShoppingCartOutline />
      </TabbarItem>
    </Tabbar>
  );
}

export default MyTabBar;
