import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Group from "@vkontakte/vkui/dist/components/Group/Group";
import Avatar from "@vkontakte/vkui/dist/components/Avatar/Avatar";
import { Icon16Add } from "@vkontakte/icons";
import { Header, SimpleCell, Button, Separator ,FormItem,FormLayout,Input,Link,Checkbox,Textarea,Select,Radio,CellButton} from "@vkontakte/vkui";
import { appUrl, shopId } from "./../config";
import { Icon16MinusCircleOutline } from "@vkontakte/icons";

import MyTabBar from "./MyTabBar";
function CheckoutForm({ id, go, store, dispatchStore }) {
  const [formState, setFormState] = useState({});

  const onChange=useCallback((e) =>{
    const { name, value } = e.currentTarget;
    setFormState( old => { return {...old, [name]: value}; });
  },[])

  return (
    <Panel id={id}>
      <PanelHeader>Оформление заказа</PanelHeader>
      <PanelHeader>Регистрация</PanelHeader>
          <Group>
          <FormLayout>
           

            <FormItem top="Номер телефона">
              <Input type="tel" placeholder="Номер телефона" value={formState.phone} />
            </FormItem>
           

            <FormItem top="Пол">
              <Select 
                placeholder="Выберите пол"
                options={[{
                  value: '0', label: 'Мужской' }, { 
                  value: '1', label: 'Женский' }
                ]}
              />
            </FormItem>

            <FormItem top="Форма оплаты">
              <Radio name="type">Наличные</Radio>
              <Radio name="type">Карта</Radio>
            </FormItem>

          
            
            <FormItem top="Адрес доставки">
              <Textarea name="deliberyAddress"
              value={formState.deliberyAddress} onChange={onChange}/>
            </FormItem>

            <FormItem top="Коментарий">
              <Textarea name="comment"
              value={formState.comment} onChange={onChange}/>
            </FormItem>
            <Checkbox>Согласен со всем <Link>этим</Link></Checkbox>
            <FormItem>
              <Button size="l" stretched>Подтвердить</Button>
            </FormItem>
          </FormLayout>
          </Group>
      <MyTabBar go={go} store={store} />
    </Panel>
  );
}

CheckoutForm.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default CheckoutForm;
