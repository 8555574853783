import React, { useState, useEffect, useCallback, useReducer } from "react";
import bridge from "@vkontakte/vk-bridge";
import {
  Root,
  View,
  ConfigProvider,
  AdaptivityProvider,
  Button,
} from "@vkontakte/vkui";

import ScreenSpinner from "@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner";
import "@vkontakte/vkui/dist/vkui.css";

//import Home from "./panels/Home";
import Persik from "./panels/Persik";

import Category from "./panels/Category";
import Position from "./panels/Position";
import Checkout from "./panels/Checkout";
import CheckoutForm from "./panels/CheckoutForm";

import { reducer, initialState } from "./hook/useStoreHook";

const myReducer = reducer(bridge);
const App = () => {
  const [activePanel, setActivePanel] = useState("category");
  const [activeParams, setActiveParams] = useState(null);
  const [history, setHistory] = useState([]);
  const [fetchedUser, setUser] = useState(null);
  const [popout, setPopout] = useState(<ScreenSpinner size="large" />);

  const [store, dispatchStore] = useReducer(myReducer, initialState);
  console.log(store);
  useEffect(() => {
    bridge.subscribe(({ detail: { type, data } }) => {
      if (type === "VKWebAppUpdateConfig") {
        const schemeAttribute = document.createAttribute("scheme");
        schemeAttribute.value = data.scheme ? data.scheme : "client_light";
        document.body.attributes.setNamedItem(schemeAttribute);
      }
    });
    async function fetchData() {
      const user = await bridge.send("VKWebAppGetUserInfo");
      setUser(user);
      setPopout(null);
    }
    fetchData();

    bridge
      .send("VKWebAppStorageGet", { keys: ["orderChekout"] })
      .then((result) => {
        var t = (result.keys.find((x) => x.key == "orderChekout") || {}).value;
        if (!!t) {
          var k = JSON.parse(t);
          if (k && Array.isArray(k)) {
            dispatchStore({ type: "setOrderCheckout", data: k });
          }
        }
      })
      .catch((error) => {
        debugger;
        console.error(error);
      });
  }, []);

  const go = useCallback((e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    setActiveParams(e.currentTarget.dataset);
    setActivePanel(e.currentTarget.dataset.to);
  }, []);
  const goBack = useCallback(() => {
    const historyNew = [...this.state.history];
    historyNew.pop();
    const activePanel = historyNew[history.length - 1];
    if (activePanel === "main") {
      bridge.send("VKWebAppEnableSwipeBack");
    }
    setHistory(historyNew);
    setActivePanel(activePanel);
  }, [history]);

  return (
    <ConfigProvider isWebView={true}>
      <AdaptivityProvider>
        <Root activeView={"root-view"}>
          <View
            id="root-view"
            activePanel={activePanel}
            popout={popout}
            history={history}
            onSwipeBack={goBack}
          >
            <Category
              id="category"
              go={go}
              dispatchStore={dispatchStore}
              store={store}
            />
            <Checkout
              id="checkout"
              go={go}
              dispatchStore={dispatchStore}
              store={store}
            />
            <CheckoutForm
              id="checkout-form"
              go={go}
              dispatchStore={dispatchStore}
              store={store}
            />
            <Position
              id="position"
              go={go}
              params={activeParams}
              dispatchStore={dispatchStore}
              store={store}
            />
            {/*  <Home id="home" fetchedUser={fetchedUser} go={go} />*/}
            <Persik id="persik" go={go} />
          </View>
        </Root>
      </AdaptivityProvider>
    </ConfigProvider>
  );
};

export default App;
