import React, { useState, useEffect, useCallback, useReducer } from "react";

export const initialState = {
  orderCkeckout: [],
};
export const initOrderPositionInCheckout = {
  itemId: null,
  categoryId: "1047bc40-52f6-463d-a04d-9429de6df20c",
  description: "Энергетический напиток",
  id: "32891929-94ac-4ed2-a9c5-e5565d53b932",
  imageUrl:
    "https://brestburger.by/wp-content/uploads/2018/11/burn-600x600.png",
  name: "Берн 0,33",
  price: 4,
  count: 0,
};
export function reducer(vkBridge) {
  return function (state, action) {
    switch (action.type) {
      case "setOrderCheckout":
        return { orderCkeckout: action.data };
      case "addOrderPoition": {
        let newOrderCheckout = Array.from(state.orderCkeckout);
        let position = newOrderCheckout.find((x) => x.itemId == action.data.id);
        if (!position) {
          position = action.data;
          position.count = 1;
          position.itemId = action.data.id;
          newOrderCheckout.push(position);
        } else {
          position.itemId = action.data.id;

          position.count = position.count + 1;
        }
        vkBridge.send("VKWebAppStorageSet", {
          key: "orderChekout",
          value: JSON.stringify(newOrderCheckout),
        });
        return { orderCkeckout: newOrderCheckout };
      }
      case "removeOrderPoition": {
        let newOrderCheckout = Array.from(state.orderCkeckout);
        let position = newOrderCheckout.find((x) => x.itemId == action.data.id);
        if (position) {
          if (position.count > 1) {
            position.count -= 1;
          } else {
            const index = newOrderCheckout.indexOf(position);
            if (index > -1) {
              newOrderCheckout.splice(index, 1);
            }
          }
          vkBridge.send("VKWebAppStorageSet", {
            key: "orderChekout",
            value: JSON.stringify(newOrderCheckout),
          });
        }
        return { orderCkeckout: newOrderCheckout };
      }
      case "Get":
        return { count: state.count - 1 };
      default:
        throw new Error();
    }
  };
}
